import {FC} from "react";
import {IMenuItem} from "../../types/types";
import {NavLink} from "react-router-dom";

interface IMenuItemProps {
    item: IMenuItem
}

const MenuItem:FC<IMenuItemProps> = ({item}) => {
    return (
        <li>
            <NavLink to={"/" + item.slug} exact={!item.slug}>
                {item.name}<span>{item.count}</span>
            </NavLink>
        </li>
    );
}

export default MenuItem;