import s from "./style.module.scss";
import {Col, Row, Container} from "react-bootstrap";
import StayConnected from "../../components/StayConnected";
import ArticlesBlockSide from "../../components/ArticlesBlockSide";
import TagsSide from "../../components/TagsSide";
import MainPageArticleBlock from "../../components/MainPageArticleBlock";
import MainPageCategories from "../../components/MainPageCategories";
import {useEffect} from "react";

const HomePage = () => {
    useEffect( () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return (
        <>
            <Container className={s.main_page_first_container}>
                <MainPageArticleBlock api_endpoint={'is_important=2'} />
            </Container>
            <Container>
                <Row>
                    <Col xs={{span: 12, order: 1}} lg={{span: 8, order: 0}}>
                        <MainPageCategories />
                    </Col>
                    <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 0}} >
                        <ArticlesBlockSide api_endpoint={'most_views=1'} name={'Популярное'} />
                        <StayConnected />
                        <TagsSide tags={[
                            {name: 'Политика', url: '#'},
                            {name: 'Путешествия', url: '#'} ,
                            {name: 'Власть', url: '#'} ,
                            {name: 'Чехия', url: '#'},
                            {name: 'История', url: '#'},
                            {name: 'Новости', url: '#'},
                            {name: 'Политика', url: '#'},
                            {name: 'Путешествия', url: '#'} ,
                            {name: 'Власть', url: '#'} ,
                            {name: 'Чехия', url: '#'},
                            {name: 'История', url: '#'},
                            {name: 'Новости', url: '#'}
                        ]} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HomePage;