import s from "./style.module.scss";
import {IArticleListItem, IArticleUrlParams} from "../../types/types";
import { useParams, Redirect } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import axios from "axios";
import {useEffect, useState} from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import {Helmet} from "react-helmet";
import StayConnected from "../../components/StayConnected";
import TagsSide from "../../components/TagsSide";
import ArticlesBlockSide from "../../components/ArticlesBlockSide";
import TopicBox from "../../components/TopicBox";
import ArticleComments from "../../components/ArticleComments";
import MainPageCategories from "../../components/MainPageCategories";


const ArticlePage = () => {
    const urlParams:IArticleUrlParams = useParams();
    const [articleItm, setArticleItem] = useState<IArticleListItem | null>(null);
    const [redirection, setRedirection] = useState('');

    useEffect( () => {
       fetchArticleItem();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [urlParams, redirection])


    const fetchArticleItem = async () => {
        try {
            const response = await axios.get<IArticleListItem>(process.env.REACT_APP_API_ADDR + 'articles/' + urlParams.articleId + '?expand=author,category');
            if (typeof response.data.id !== 'undefined'
                && (response.data.url !== '/' + urlParams.articleCategorySlug + '/' + urlParams.articleId + '-' + urlParams.articleSlug)) {
                setRedirection("/404");
            } else {
                setArticleItem(response.data);

                let pageContent = response.data?.text || '';
                let extractscript = pageContent.match(/<script type="text\/javascript">([\s\S]*?)<\/script>/gs);

                let vkBlocks = document.querySelectorAll('div[id^="vk_post_"]');
                var i;
                for (i = 0; i < vkBlocks.length; i++) {
                    vkBlocks[i].innerHTML = "";
                }

                if (extractscript) {
                    document.querySelectorAll('section[id^="table__"]')
                    for (const match of extractscript) {
                        window.eval(match.replace('<script type="text/javascript">', '').replace('</script>', ''))
                    }
                }
            }
        } catch (e) {
            console.error(e);
            setRedirection("/404");
        }
    }

    if (redirection !== '') {
        return (
            <Redirect to={redirection} />
        );
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{articleItm?.meta_title}</title>
                <meta name='description' content={articleItm?.meta_description} />
                <meta name='keywords' content={articleItm?.meta_keywords} />
                <script src="https://yastatic.net/share2/share.js"></script>
                <script type="text/javascript" src="https://vk.com/js/api/openapi.js?169"></script>
            </Helmet>
            <BreadCrumbs currentName={articleItm?.category.name} color={articleItm?.category.color} currentPage={articleItm?.name} items={[{name: articleItm?.category.name, url: "/" + articleItm?.category.slug}]}/>
            <Container className={s.article_item_container}>
                <Row>
                    <Col xs={{span: 12, order: 1}} lg={{span: 8, order: 0}}>
                        <div className={s.news_details_layout}>
                            <TopicBox color={articleItm?.category.color} name={articleItm?.category.name} doShort={true}/>
                            <img alt={ articleItm?.name } src={articleItm?.photo_path + '730x451/' + articleItm?.photo}/>
                            <div className={s.photo_text}>{articleItm?.photo_text}</div>
                            <h2>{ articleItm?.name }</h2>

                            <ul className={s.post_info_dark}>
                                <li>
                                    <a href={"#"}>
                                        <span>Автор:</span> {articleItm?.author.name} {articleItm?.author.last_name}</a>
                                </li>
                                <li>
                                    <a href={"#"}>
                                        <i className="far fa-calendar-alt" aria-hidden="true"/>{articleItm?.publish_date}</a>
                                </li>
                                <li>
                                    <a href={"#"}>
                                        <i className="far fa-eye" aria-hidden="true"/>{articleItm?.views}</a>
                                </li>
                            </ul>

                            <div className={s.article_content} >
                                { <div dangerouslySetInnerHTML={{__html: articleItm?.text || ''}}/> }
                            </div>
                        </div>
                        <div className={s.shareButtons}>
                            <div className="ya-share2" data-curtain data-shape="round"
                                 data-size="l"
                                 data-services="vkontakte,facebook,odnoklassniki,telegram,twitter,lj"></div>
                        </div>
                        <div>
                            <ArticleComments articleId={articleItm?.id} />
                        </div>
                        <div>
                            <MainPageCategories />
                        </div>
                    </Col>
                    <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 0}} >
                        <StayConnected />
                        <ArticlesBlockSide api_endpoint={'last_articles=1'} name={'Последние Статьи'} />
                        <TagsSide tags={[
                            {name: 'Политика', url: '#'},
                            {name: 'Путешествия', url: '#'} ,
                            {name: 'Власть', url: '#'} ,
                            {name: 'Чехия', url: '#'},
                            {name: 'История', url: '#'},
                            {name: 'Новости', url: '#'},
                            {name: 'Политика', url: '#'},
                            {name: 'Путешествия', url: '#'} ,
                            {name: 'Власть', url: '#'} ,
                            {name: 'Чехия', url: '#'},
                            {name: 'История', url: '#'},
                            {name: 'Новости', url: '#'}
                        ]} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ArticlePage;