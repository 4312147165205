import cn from "classnames";
import s from "./style.module.scss";

import {useEffect, useState} from "react";
import {FC} from "react";
import {IMainMenuItem} from "../../types/types";
import axios from "axios";
import MainMenuItem from "./MainMenuItem";

const MainMenu: FC = () => {
    const [menuItems, setMenuItems] = useState<IMainMenuItem[]>([]);
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        fetchMenuItems();
    }, []);

    const hideMenu = () => {
        setActive(false);
    };

    const toggleMenu = () => {
        setActive(!isActive);
    };

    async function fetchMenuItems() {
        try {
            const response = await axios.get<IMainMenuItem[]>(process.env.REACT_APP_API_ADDR + 'main-menu');
            console.log(response.data);
            setMenuItems(response.data)
        } catch(e) {
            console.error(e)
        }
    }

    return (
        <>
            <div className={cn(s.nav_icon, (isActive ? s.nav_icon_open : ''))} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav className={cn(s.main_menu, (isActive ? s.active : ''))} onClick={hideMenu}>
                {menuItems.map(item =>
                    <MainMenuItem item={item} key={item.id} />
                )}
            </nav>
        </>
    );
};

export default MainMenu;