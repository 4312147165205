import s from "./style.module.scss";
import cn from "classnames";
import {FC} from "react";
import { IArticleListItemShort} from "../../types/types";
import {NavLink} from "react-router-dom";
import {Col} from "react-bootstrap";
import TopicBox from "../TopicBox";

interface IArticleListItemProps {
    item: IArticleListItemShort
    imageSize?: string,
    key: number,
    topicBox?: boolean,
    isSmall?: boolean,
    author?: boolean,
    intro?: boolean,
}

const ArticleListItem :FC <IArticleListItemProps> = ({item, imageSize= '577x450', topicBox= true, isSmall = false, author = true, intro = true}) => {
    return (
        <Col xl={12} lg={6} md={6} sm={12}>
            <div className={cn (s.article_list_item, isSmall ? s.small : '')}>
                <div className={s.img_container}>
                    <NavLink to={item.url} className={s.img_nav_link}>
                        <img src={item.photo_path + imageSize + '/' + item.photo} alt={item.name} />
                    </NavLink>
                    {
                        topicBox ? <TopicBox color={item.category.color} name={item.category.name}></TopicBox> : ''
                    }
                </div>
                <div className={s.text_container}>
                    <div className={s.article_meta}>
                        <ul>
                            {
                                author ? (<li>
                                    <span>Автор:</span>
                                    <a href="#">{item.author.name} {item.author.last_name}</a>
                                </li>) : ''
                            }

                            <li>
                                <span>
                                    <i className="fas fa-calendar" aria-hidden="true"></i>
                                </span>
                                {item.publish_date}
                            </li>
                        </ul>
                    </div>
                    <h3>
                        <NavLink to={item.url}>{item.name}</NavLink>
                    </h3>
                    {
                        intro ? (
                            <p>
                                {item.intro_text}
                            </p>
                        ) : ''
                    }
                </div>
            </div>
        </Col>
    );
}

export default ArticleListItem;