import cn from "classnames";
import s from "./style.module.scss";
import {FC, useState} from "react";
import MainMenu from "../MainMenu";
import HeaderActions from "../HeaderActions";
import {Container, Row, Col} from "react-bootstrap";

import logoSvg from "./assets/logo2.svg";
import {NavLink} from "react-router-dom";
import Weather from "../Weather";

const Header: FC = () => {
    const [headerFixed, setHeaderFixed] = useState(false);

    window.addEventListener('scroll', function(){
        setHeaderFixed(window.pageYOffset > 50);
    });

    let date = new Date();

    let dateString = date.toLocaleString('ru', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    return (
        <>
            <header className={cn(s.header, (headerFixed ? s.fixed : ""))}>
                <Container className={s.header_container}>
                    <Row>
                        <Col xs={{span: 4, order: 0}} lg={{span: 2, order: 0}} >
                            <NavLink to={"/"} className={s.logo}>
                                <img src={logoSvg} alt={'RU.CZ'}/>
                            </NavLink>
                        </Col>
                        <Col xs={{span: 2, order: 1}} lg={{span: 8, order: 1}} className={s.header_menu_container}>
                            <MainMenu />
                        </Col>
                        <Col xs={6} lg={{span: 2, order: 3}} className={s.header_action_container}>
                            {/*<HeaderActions/>*/}
                        </Col>
                    </Row>
                </Container>
            </header>

            <div className={s.header_row}>
                <Container>
                    <Row>
                        <Col>
                            <Weather />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Header;