import s from "./style.module.scss";
import {FC} from "react";
import {IArticleListItemShort} from "../../types/types";
import {NavLink} from "react-router-dom";
import TopicBox from "../TopicBox";

interface IArticleItemProps {
    item: IArticleListItemShort
    imageSize?: string,
    isMain?: boolean,
    isAuto?: boolean
    withTopicBox?: boolean
}

const ArticleItem :FC <IArticleItemProps> = ({item, imageSize= '577x450', isMain = true, isAuto = false, withTopicBox= true}) => {
    let img_class = imageSize === '577x450' ? s.big : (imageSize === '820x316' ? s.mid : s.small );

    if (isAuto) {
        img_class = '';
    }

    if (item) {
        return (
            <div className={s.article_item}>
                <div className={s.img_overlay}>
                    <img src={item.photo_path + imageSize + '/' + item.photo} alt={item.name} className={img_class} />
                    {(withTopicBox) ? (
                        <TopicBox color={item.category.color} name={item.category.name} href={item.category.slug} />
                    ) : ("")}

                    <div className={s.mask_content_lg}>
                        {
                            (isMain) ? (
                                <>
                                    <div className={s.article_meta}>
                                        <ul>
                                            <li>
                                                <span>Автор:</span>
                                                <a href={"#"}>{item.author.name} {item.author.last_name}</a>
                                            </li>
                                            <li>
                                <span>
                                    <i className="fas fa-calendar" aria-hidden="true" />
                                </span>
                                                {item.publish_date}
                                            </li>
                                        </ul>
                                    </div>
                                    <h1>
                                        <NavLink to={item.url} className={s.img_nav_link}>
                                            {item.name}
                                        </NavLink>
                                    </h1>
                                </>
                            ) : (
                                <h2>
                                    <NavLink to={item.url} className={s.img_nav_link}>
                                        {item.name}
                                    </NavLink>
                                </h2>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    };
}

export default ArticleItem;