import s from "./style.module.scss";
import {FC} from "react";
import cn from "classnames";
import {NavLink} from "react-router-dom";

interface ITopicBoxProp {
    color?: string;
    name?: string;
    addClass?: string;
    doShort?: boolean;
    href?: string;
}

const TopicBox: FC<ITopicBoxProp> = ({color, name,addClass='', doShort=false, href= ''}) => {

    let fullName = name;

    if (doShort && name && name?.length > 12) {
        name = name.slice(0, 10) + '...';
    }

    return (
        <>
        { href ? (
                <NavLink to={href} className={cn(s.topic_box_top, addClass)}>
                    <div className={cn(s.topic_box_sm, color)} title={fullName}>{name}</div>
                </NavLink>
            ) : (
                <div className={cn(s.topic_box_top, addClass)}>
                    <div className={cn(s.topic_box_sm, color)} title={fullName}>{name}</div>
                </div>
            )
        }
        </>
    );
};

export default TopicBox;