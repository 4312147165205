import {FC, useEffect, useState} from "react";
import s from "./style.module.scss";
import {IMenuItem} from "../../types/types";
import axios from "axios";
import MenuItem from "./MenuItem";

interface IFooterBoxCategoryProps {
    listType: string,
    title: string,
}

const FooterBoxCategory:FC<IFooterBoxCategoryProps> = ({listType, title}) => {
    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

    useEffect(() => {
        fetchMenuItems();
    }, []);

    async function fetchMenuItems() {
        try {
            const response = await axios.get<IMenuItem[]>(process.env.REACT_APP_API_ADDR + 'bottom-menu');
            setMenuItems(response.data)
        } catch(e) {
            console.error(e)
        }
    }

    return (
        <div className={s.footer_box}>
            <h2 className={s.box_title}>Все категории</h2>
            <ul className={s.categories_list}>
                {menuItems.map(item =>
                    <MenuItem item={item} key={item.id} />
                )}
            </ul>
        </div>
    )
}

export default FooterBoxCategory;