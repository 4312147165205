import cn from "classnames";
import s from "./style.module.scss";
import {FC} from "react";
import {NavLink} from "react-router-dom";

interface ITagsSideProps {
    tags: {
      name: string,
      url: string
    }[]
}

const TagsSide: FC<ITagsSideProps> = ({tags}) => {

    return (
        <div className={s.tags_side}>
            <div className={cn(s.topic_border, "color_cinnabar")}>
                <div className={cn(s.topic_box_lg, "color_cinnabar")}>Тэги</div>
            </div>

            <ul className={s.tags_list}>
                {tags.map(item =>
                    <li key={item.url}><NavLink to={item.url}>{item.name}</NavLink></li>
                )}
            </ul>
        </div>
    );
};

export default TagsSide;