import { Route } from "react-router-dom";
import Header from './components/Header';
import Footer from "./components/Footer";

import HomePage from "./routs/Home";
import CategoryPage from "./routs/Category";
import ArticlePage from "./routs/Article";

import axios from "axios";
import {useEffect, useState} from "react";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
    const [categoryRoutes, setCategoryRoutes] = useState('')

    useEffect( () => {
        fetchCategoryRoutes();
    }, [])

    const fetchCategoryRoutes = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_ADDR + 'categories/category-routes');
            setCategoryRoutes('(' + response.data.join('|') + ')');
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Header/>
            <Route path={'/'} exact component={HomePage} />
            <Route path={'/:articleCategorySlug' + categoryRoutes} exact component={CategoryPage} />
            <Route path={'/:articleCategorySlug' + categoryRoutes + '/:articleId-:articleSlug'} component={ArticlePage} />
            <Footer />
            <ScrollToTop />
        </>
    );
};

export default App;