import s from "./style.module.scss";
import {Helmet} from "react-helmet";
import {Container, Row, Col} from "react-bootstrap";
import {FC, useEffect, useState} from "react";
import {ICategoryUrlParams, ICategoryItem, IArticleList} from "../../types/types";

import {useParams} from "react-router-dom";
import axios from "axios";
import ArticleList from "../../components/ArticleList";
import BreadCrumbs from "../../components/BreadCrumbs";
import StayConnected from "../../components/StayConnected";
import ArticlesBlockSide from "../../components/ArticlesBlockSide";

const CategoryPage:FC = () => {
    const urlParams:ICategoryUrlParams = useParams();
    const [categoryItem, setCategoryItem] = useState<ICategoryItem | null>(null);

    useEffect( () => {
        fetchCategoryItem();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [urlParams])

    const fetchCategoryItem = async () => {
        try {
            const response = await axios.get<ICategoryItem>(process.env.REACT_APP_API_ADDR + 'categories/' + urlParams.articleCategorySlug);
            setCategoryItem(response.data);
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div className={s.category_page}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{categoryItem?.meta_title}</title>
                <meta name='description' content={categoryItem?.meta_description} />
                <meta name='keywords' content={categoryItem?.meta_keywords} />
                <link rel="canonical" href="/" />
            </Helmet>
            <BreadCrumbs currentName={categoryItem?.name} color={categoryItem?.color} currentPage={'Все статьи'} items={[]}/>
            <Container className={s.category_page_container}>
                <Row>
                    <Col xs={{span: 12, order: 1}} lg={{span: 8, order: 0}}>
                        <ArticleList />
                    </Col>
                    <Col xs={{span: 12, order: 1}} lg={{span: 4, order: 0}} >
                        <StayConnected />
                        <ArticlesBlockSide api_endpoint={'most_views=1'} name={'Популярные'} />


                        <ArticlesBlockSide api_endpoint={'last_articles=1'} name={'Последние Статьи'} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CategoryPage;