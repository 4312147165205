import s from "./style.module.scss";
import {FC} from "react";
import {IMainMenuItem} from "../../types/types";
import {NavLink} from "react-router-dom";

interface MainMenuItemProps {
    item: IMainMenuItem
}

const MainMenuItem:FC<MainMenuItemProps> = ({item}) => {
    return (
        <NavLink className={s.main_menu_item} activeClassName={s.active_item} to={"/" + item.slug} exact={!item.slug}>
            {item.name}
        </NavLink>
    );
}

export default MainMenuItem;