import React, {FC, useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import {IArticleList, ICategoryUrlParams} from "../../types/types";
import ArticleListItem from "../ArticleListItem";
//import ArticlePagination from "../ArticlePagination";
import axios from "axios";
import {useParams} from "react-router-dom";


const ArticleList: FC = () => {
    const urlParams:ICategoryUrlParams = useParams();
    //const params = new URLSearchParams(window.location.search);
    const [articleList, setArticleList] = useState<IArticleList | null>(null);
    let [currentPage, setCurrentPage] = useState<number>(1);
    const curRef = React.createRef<HTMLDivElement>();
    let loadLock = false;

    useEffect( () => {
        fetchArticles(true);

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [urlParams])

    const fetchArticles = async (reset:boolean = false) => {
        try {
            let page = reset ? 1 : currentPage;

            const response = await axios.get<IArticleList>(process.env.REACT_APP_API_ADDR + 'articles?expand=author,category&category_slug=' + urlParams.articleCategorySlug + '&page=' + page);

            if (articleList !== null && currentPage !== 1 && !reset) {
                let newData = response.data;
                newData.items = [...articleList.items, ...newData.items]
                setArticleList(newData);
            } else {
                setArticleList(response.data);
            }

            setCurrentPage((response.data?._meta.currentPage || 0) + 1);

            loadLock = false;
        } catch (e) {
            console.error(e);
        }
    }

    const scrollAction = () => {
        if (curRef.current?.scrollHeight != undefined) {
            let pageCountInt = articleList?._meta.pageCount || 0;
            let currentPageInt = articleList?._meta.currentPage || 0;

            if (window.scrollY > curRef.current?.scrollHeight - 500 && !loadLock && pageCountInt > currentPageInt) {
                loadLock = true;
                fetchArticles();
            }
        }
    };

    window.addEventListener("scroll", scrollAction);

    return (
        <div ref={curRef}>
            <Container>
                <Row>
                    {articleList?.items.map(item =>
                        <ArticleListItem item={item} key={item.id} />
                    )}
                </Row>
            </Container>
            {/*<Container>*/}
            {/*    <ArticlePagination meta={articleList?._meta} />*/}
            {/*</Container>*/}
        </div>
    );
};

export default ArticleList;