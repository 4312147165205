import s from "./style.module.scss";
import {FC} from "react";
import {IComment} from "../../types/types";

const CommentItem :FC <IComment> = ({firstName,lastName,message,date, answers}) => {

    return (
        <div>
            <div className={s.comment_item}>
                <div className={s.avatar_container}>
                    <div className={s.avatar}>
                        {firstName?.substring(0, 1)}{lastName?.substring(0, 1)}
                    </div>
                </div>
                <div className={s.content_comment}>
                    <div className={s.comment_head}>
                        <div className={s.comment_name}>{firstName} {lastName}</div>
                        <div className={s.comment_date}>{date}</div>
                    </div>
                    <div className={s.comment_text}>
                        {message}
                    </div>
                    <div className={s.comment_controls}>
                        <div className={s.control_itm}>Ответить</div>
                    </div>
                </div>
            </div>
            <div className={s.answers}>
                {answers?.map((item, key) =>
                    <CommentItem key={key} firstName={item.firstName} lastName={item.lastName} message={item.message} date={item.date} answers={item.answers} />
                )}
            </div>
        </div>
    );
}

export default CommentItem;