import s from "./style.module.scss";
import cn from "classnames";
import React, {FC, useState} from "react";
import CommentItem from "./CommentItem";

interface IArticleCommentsProps {
    articleId?: number
}

const comments = [
    {
        articleId: 1,
        firstName: 'Василий',
        lastName: 'Пупкин',
        message: 'Идейные соображения высшего порядка, а также новая модель организационной деятельности способствует подготовки и реализации соответствующий условий активизации. Не следует, однако забывать, что начало повседневной работы по формированию позиции представляет собой интересный эксперимент проверки позиций, занимаемых участниками в отношении поставленных задач.',
        date: '12.02.2021',
        answers: []
    },
    {
        articleId: 1,
        firstName: 'Пётр',
        lastName: 'Васичкин',
        message: 'Задача организации, в особенности же новая модель организационной деятельности представляет собой интересный эксперимент проверки существенных финансовых и административных условий. С другой стороны рамки и место обучения кадров играет важную роль в формировании новых предложений',
        date: '12.03.2021',
        answers: []
    },
    {
        articleId: 1,
        firstName: 'Стрелка',
        lastName: 'Осцилографа',
        message: 'С другой стороны постоянное информационно-пропагандистское обеспечение нашей деятельности представляет собой интересный эксперимент проверки систем массового участия. С другой стороны дальнейшее развитие различных форм деятельности требуют от нас анализа направлений прогрессивного развития.',
        date: '12.04.2021',
        answers: []
    },
    {
        articleId: 1,
        firstName: 'Анатолий',
        lastName: 'Анатольевич',
        message: 'Значимость этих проблем настолько очевидна, что консультация с широким активом влечет за собой процесс внедрения и модернизации направлений прогрессивного развития. Идейные соображения высшего порядка, а также рамки и место обучения кадров представляет',
        date: '12.03.2021',
        answers: [
            {
                articleId: 1,
                firstName: 'Василий',
                lastName: 'Пупкин',
                message: 'Идейные соображения высшего порядка, а также новая модель организационной деятельности способствует подготовки и реализации соответствующий условий активизации. Не следует, однако забывать, что начало повседневной работы по формированию позиции представляет собой интересный эксперимент проверки позиций, занимаемых участниками в отношении поставленных задач.',
                date: '12.04.2021',
                answers: []
            },
            {
                articleId: 1,
                firstName: 'Пётр',
                lastName: 'Васичкин',
                message: 'Задача организации, в особенности же новая модель организационной деятельности представляет собой интересный эксперимент проверки существенных финансовых и административных условий. С другой стороны рамки и место обучения кадров играет важную роль в формировании новых предложений',
                date: '12.03.2021',
                answers: []
            },
        ]
    }
    ,
    {
        articleId: 1,
        firstName: 'Иван',
        lastName: 'Иванов',
        message: 'Не следует, однако забывать, что консультация с широким активом требуют от нас анализа систем массового участия. С другой стороны начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке направлений прогрессивного развития. ',
        date: '12.05.2021',
        answers: []
    }
];

const ArticleComments :FC <IArticleCommentsProps> = ({articleId}) => {
    const [showAll, setShowAll] = useState<boolean>(false)
    const lastComment = comments[comments.length - 1]
    const inputRef = React.createRef<HTMLDivElement>();

    const showAllHandle = () => {

        if (showAll) {
            window.scrollTo({
                top: inputRef.current?.offsetTop,
                behavior: "smooth"
            });
        }

        setShowAll(!showAll);
    }

    return (
        <div ref={inputRef} className={s.article_comments}>
            <h2>Комментарии:</h2>
            <div className={cn(s.comments_block, showAll ? s.show_all : '')}>
                {comments.map((item, key) =>
                    <CommentItem key={key} firstName={item.firstName} lastName={item.lastName} message={item.message} date={item.date} answers={item.answers} />
                )}
                <div className={s.button_container}>
                    <button className={s.button} onClick={showAllHandle}>Свернуть комментарии</button>
                </div>
            </div>
            <div className={cn(s.last_comment_block, showAll ? s.show_all : '')} >
                <CommentItem firstName={lastComment.firstName} lastName={lastComment.lastName} message={lastComment.message} date={lastComment.date} answers={[]} />
                <div className={s.button_container}>
                    <button className={s.button} onClick={showAllHandle}>Все комментарии</button>
                </div>
            </div>
        </div>
    );
}

export default ArticleComments;