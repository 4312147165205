import cn from "classnames";
import s from "./style.module.scss";
import {FC} from "react";


const StayConnected: FC = () => {

    return (
        <div className={s.stay_connected}>
            <div className={cn(s.topic_border, "color_cinnabar")}>
                <div className={cn(s.topic_box_lg, "color_cinnabar")}>Оставайтесь на связи</div>
            </div>
            <ul className={s.stay_connected_list}>
                <li className={s.facebook}>
                    <a href="#">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                        <div className={s.connection_quantity}>50.2 k</div>
                        <p>Фаны</p>
                    </a>
                </li>
                <li className={s.twitter}>
                    <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                        <div className={s.connection_quantity}>10.3 k</div>
                        <p>Фоловеры</p>
                    </a>
                </li>
                <li className={s.linkedin}>
                    <a href="#">
                        <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                        <div className={s.connection_quantity}>25.4 k</div>
                        <p>Фаны</p>
                    </a>
                </li>
                <li className={s.rss}>
                    <a href="#">
                        <i className="fa fa-rss" aria-hidden="true"></i>
                        <div className={s.connection_quantity}>20.8 k</div>
                        <p>Подписчики</p>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default StayConnected;