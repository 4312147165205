import cn from "classnames";
import s from "./style.module.scss";
import {FC, useEffect, useState} from "react";
import {IArticleList} from "../../types/types";
import axios from "axios";
import {Container, Row, Col} from "react-bootstrap";
import ArticleItem from "../ArticleItem";

interface IMainPageArticleBlock {
    api_endpoint: string
}

const MainPageArticleBlock:FC<IMainPageArticleBlock> = ({api_endpoint}) => {

    const [articleList, setArticleList] = useState<IArticleList | null>(null);

    useEffect( () => {
        fetchArticles();
    }, [])

    const fetchArticles = async () => {
        try {
            const response = await axios.get<IArticleList>(process.env.REACT_APP_API_ADDR + 'articles?expand=author,category&' + api_endpoint);
            setArticleList(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    if (articleList) {
        return (
            <div className={s.main_page_articles_block}>
                <Container className={s.main_page_articles_block_container}>
                    <Row className={s.main_row}>
                        <Col xs={12} lg={6} className={cn(s.no_padding, s.no_padding_main)}>
                            <ArticleItem item={articleList.items[0]} isMain />
                        </Col>
                        <Col lg={6} md={12} className={s.no_padding}>
                            <Row className={s.main_row}>
                                <Col xs={12} className={s.no_padding}>
                                    <ArticleItem isMain={false} item={articleList.items[1]} imageSize={"820x316"} />
                                </Col>
                                <Col sm={6} xs={12} className={s.no_padding}>
                                    <ArticleItem isMain={false} item={articleList.items[2]} imageSize={"730x577"} />
                                </Col>
                                <Col sm={6} xs={12} className={s.no_padding}>
                                    <ArticleItem isMain={false} item={articleList.items[3]} imageSize={"730x577"} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    } else {
        return (
            <></>
        );
    }

};

export default MainPageArticleBlock;