import cn from "classnames";
import s from "./style.module.scss";
import {FC, useEffect, useState} from "react";
import axios from "axios";
import {Row, Col} from "react-bootstrap";
import {ITabList} from "../../types/types";
import ArticleItem from "../ArticleItem";
import ArticleListItem from "../ArticleListItem";
import {NavLink} from "react-router-dom";

const MainPageCategories: FC = () => {

    const [TabList, setTabList] = useState<ITabList[] | null>(null);

    useEffect( () => {
        fetchTabs();
    }, [])

    const fetchTabs = async () => {
        try {
            const response = await axios.get<ITabList[]>(process.env.REACT_APP_API_ADDR + 'main-page-tab');
            setTabList(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            {TabList?.map((category, key) =>
                (
                    <div className={s.tabs_block} key={key}>
                        <Row className={s.head}>
                            <Col xs={12}>
                                <div className={cn(s.tab_buttons_nav, "flex-row", "bb_" + category.color)}>
                                    <div className={cn(s.topic_border)}>
                                        <NavLink to={category.slug} className={cn(s.topic_box_lg, category.color)}>{category.name}</NavLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div >
                                    <Row>
                                        <Col xs={12} md={6} className={s.main_tab_article}>
                                            <ArticleItem withTopicBox={false} item={category.articles[0]} isAuto imageSize={"800x1156"} />
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row className={s.second_tab_article}>
                                                {category.articles.map(function (item, key){
                                                    item.category = category;

                                                    if (!key) {
                                                        return '';
                                                    }

                                                    return (
                                                        <ArticleListItem item={item} key={key} isSmall imageSize={"500x320"} intro={false} author={false} topicBox={false}/>
                                                    )
                                                })}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            )}
        </>
    );
};

export default MainPageCategories;