import s from "./style.module.scss";
import {FC} from "react";
import {NavLink} from "react-router-dom";
import {Container} from "react-bootstrap";

interface IBredCrumbsProps {
    currentName?: string,
    currentPage?: string,
    color?: string,
    items: {
        name?: string,
        url?: string
    }[]
}

const BreadCrumbs:FC<IBredCrumbsProps> = ({currentName,color, currentPage, items}) => {
    return (
        <section className={s.breadcrumbs_area}>
            <Container>
                <div className={s.breadcrumbs_content}>
                    <h1 className={color ? s[color] : 'default'}>{currentName}</h1>
                    <ul>
                        <li><NavLink to={"/"} className={s.bc_item} >Главная</NavLink>-</li>
                        {items.map((item, key) =>
                            <li key={key}><NavLink to={item.url ? item.url : "/"} className={s.bc_item}>{item.name}</NavLink>-</li>
                        )}
                        <li><span className={s.bc_item}>{currentPage}</span></li>
                    </ul>
                </div>
            </Container>
        </section>
    );
}

export default BreadCrumbs;