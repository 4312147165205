import s from "./style.module.scss";

import {FC} from "react";
import {Container, Col, Row} from "react-bootstrap";
import logoSvg from "./assets/logo.svg";
import {NavLink} from "react-router-dom";
import FooterBoxCategory from "../FooterBoxCategory";

const Footer: FC = () => {
    return (
        <footer className={s.footer}>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <FooterBoxCategory listType={'post_list'} title={'Популярные статьи'}></FooterBoxCategory>
                    </Col>
                    <Col xs={12} md={6} lg={6}>

                    </Col>
                </Row>
            </Container>
            <Container className={s.header_container}>
                <Row>
                    <Col>
                        <NavLink to={"/"} className={s.logo}>
                            <img src={logoSvg} alt={'RU.CZ'}/>
                        </NavLink>
                    </Col>
                </Row>
            </Container>
            <Container>
                <p className={s.copiright}>© 2021 RU.CZ</p>
            </Container>
        </footer>
    );
};

Footer.propTypes = {
    
};

export default Footer;