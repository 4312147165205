import s from "./style.module.scss";
import {FC, useEffect, useState} from "react";

const ScrollToTop: FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
            {isVisible &&
                <div className={s.scroll_to_top} onClick={scrollToTop}>
                    <i className="fas fa-arrow-up" aria-hidden="true" ></i>
                </div>
            }
        </>
    );
};

export default ScrollToTop;