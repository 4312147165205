import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import '@fontsource/lora/index.css';
import '@fontsource/lora/cyrillic.css';
import '@fontsource/fira-sans/index.css';
//import '@fontsource/fira-sans/cyrillic.css';

import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
  document.getElementById('root')
);
