import cn from "classnames";
import s from "./style.module.scss";
import {FC, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {IArticleList} from "../../types/types";
import axios from "axios";
import {Container, Row, Col} from "react-bootstrap";
import TopicBox from "../TopicBox";

interface IArticlesBlockSide {
    api_endpoint: string,
    name: string,
    color_class?: string,
    col?: number
}

const ArticlesBlockSide: FC<IArticlesBlockSide> = ({
   api_endpoint,
   name,
   color_class= "color_cinnabar",
   col= 6,
}) => {

    const [articleList, setArticleList] = useState<IArticleList | null>(null);

    useEffect( () => {
        fetchArticles();
    }, [])

    const fetchArticles = async () => {
        try {
            const response = await axios.get<IArticleList>(process.env.REACT_APP_API_ADDR + 'articles?expand=author,category&' + api_endpoint);
            setArticleList(response.data);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={s.articles_block_side}>
            <div className={cn(s.topic_border, color_class)}>
                <div className={cn(s.topic_box_lg, color_class)}>{name}</div>
            </div>
            <Container className={s.block_container}>
                <Row>
                    {articleList?.items.map(item =>
                        (    <Col key={item.id} xs={col} className={s.list_itm}>
                                <TopicBox color={item.category.color} name={item.category.name} doShort={true}></TopicBox>
                                <NavLink to={item.url} className={s.img_nav_link}>
                                    <img src={item.photo_path + '370x278/' + item.photo} alt={item.name} />
                                </NavLink>
                                <h3>
                                    <NavLink to={item.url}>{item.name}</NavLink>
                                </h3>
                            </Col>
                        )
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default ArticlesBlockSide;