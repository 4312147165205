import {FC, useEffect, useState} from "react";
import cn from "classnames";
import s from "./style.module.scss";
import Marquee from "react-fast-marquee";
import axios from "axios";

interface IWeatherData {
    name: string;
    keys: string[];
    apiCode: string;
    data: {
        data: {
            main: {
                temp_min: number;
                temp_max: number;
            },
            clouds: {
                all: number;
            },
            weather: {
                main: string;
            }[]
        }
    } | any;
}

const Weather: FC = () => {
    const [weather, setWeather] = useState<IWeatherData[] | null>(null);

    let cities:IWeatherData[]  = [
        {name: 'Praha', keys: ['3416e17ebc79c6b8baad8ca1393b78a8'], apiCode: 'praga', data: null},
        {name: 'Brno', keys: ['a4c40cbbf57dad81ede923d1086d51cd'], apiCode: 'brno', data: {}},
        {name: 'Ostrava', keys: ['7e1fba19f0b70908c8128395c2aa9a8c'], apiCode: 'ostrava', data: {}},
        {name: 'Karlový Vary', keys: ['fe1440199cb6a53852754c937f41d64e'], apiCode: 'Karlovy+Vary', data: {}},
        {name: 'Liberec', keys: ['89acf9c828c7dd0cf1be1a0a93e976cd'], apiCode: 'Liberec', data: {}},
        {name: 'Hradec Kralove', keys: ['bca459cecfe4ff156dcacf560f2e5829'], apiCode: 'Hradec+Kralove', data: {}},
        {name: 'České Budejovice', keys: ['5e687402fcfc821c9b65bd6974cc847f'], apiCode: 'Ceske+Budejovice', data: {}},
    ];

    useEffect( () => {
        fetchWeather();
    }, [])

    const fetchWeather = async () => {
        await cities.map(async function (item, key){
            try {
                const response = await axios.get('https://api.openweathermap.org/data/2.5/weather?q=' + item.apiCode + '&units=metric&appid=' + item.keys[0]);
                item.data = response;
            } catch (e) {
                console.error(e);
            }
        })

        setWeather(cities);
    }

    return (
        <div>
            <Marquee pauseOnHover={true} gradient={false} className={s.weather}>
                {weather && weather.map(function (item:IWeatherData, key:number){
                    let dataWeather = item.data?.data?.weather || null;

                    return dataWeather && (
                        <div key={key} className={s.item}>
                            {dataWeather && dataWeather[0].main == 'Clouds' && (<i title={dataWeather[0].Description} className="fas fa-cloud"></i>)}
                            {dataWeather && dataWeather[0].main == 'Clear' && (<i title={dataWeather[0].Description} className="fas fa-sun"></i>)}
                            {dataWeather && dataWeather[0].main == 'Snow' && (<i title={dataWeather[0].Description} className="far fa-snowflake"></i>)}
                            {dataWeather && dataWeather[0].main == 'Rain' && (<i title={dataWeather[0].Description} className="fas fa-cloud-rain"></i>)}
                            {dataWeather && dataWeather[0].main == 'Drizzle' && (<i title={dataWeather[0].Description} className="fas fa-cloud-sun-rain"></i>)}
                            {dataWeather && dataWeather[0].main == 'Thunderstorm' && (<i title={dataWeather[0].Description} className="fas fa-bolt"></i>)}

                            {item.name} {item.data.data?.main.temp_min.toFixed()} - {item.data.data?.main.temp_max.toFixed()}°C
                        </div>
                    )
                })}
            </Marquee>
        </div>
    );
};

export default Weather;